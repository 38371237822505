import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {AuthCallback} from '../../pages/AuthCallback';
import {AuthError} from '../../pages/AuthError';
import {AuthLogoutLanding} from '../../pages/AuthLogoutLanding';

type RootProps = {
  name: string;
};

export default function Root(props: RootProps) {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/auth/logout" element={<AuthLogoutLanding />}></Route>
        <Route path="/auth/callback" element={<AuthCallback />}></Route>
        <Route path="/auth/error" element={<AuthError />}></Route>
      </Routes>
    </BrowserRouter>
  );
}
