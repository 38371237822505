import {Box} from '@mui/material';
import {Auth} from '@verily-src/auth';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
export function AuthCallback() {
  const navigate = useNavigate();
  useEffect(() => {
    try {
      // First check if error and error description exist in query param.
      const urlParams = new URLSearchParams(window.location.search);
      const err = urlParams.get('error');
      const description = urlParams.get('error_description');
      if (err) {
        navigate(`/auth/error?error=${err}&error_description=${description}`);
      } else {
        Auth.handleRedirectFromReactAuth();
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <Box
      sx={{
        textAlign: 'center',
      }}
    >
      <span></span>
    </Box>
  );
}
