import {Auth} from '@verily-src/auth';
import {useEffect} from 'react';
export function AuthLogoutLanding() {
  useEffect(() => {
    try {
      Auth.logoutCompletelyFromReactAuthMFE();
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <div>
      <span></span>
    </div>
  );
}
