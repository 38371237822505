import {Box, Typography} from '@mui/material';
import {Auth} from '@verily-src/auth';
import {Button} from '@verily-src/react-design-system';
export function AuthError() {
  const urlParams = new URLSearchParams(window.location.search);
  const err = urlParams.get('error');
  const description = urlParams.get('error_description');
  return (
    <Box
      sx={{
        textAlign: 'center',
      }}
    >
      <Box
        sx={{
          p: 7,
        }}
      ></Box>
      <Typography variant="display5" component="h2" sx={{mb: 2}}>
        {'Oops! Authentication error occurred'}
      </Typography>
      <Typography variant="body1" sx={{mb: 3}}>
        {`Error:${err} Description:${description}`}
      </Typography>
      <Button onClick={() => Auth.logout('/')}>{'Home Page'}</Button>
    </Box>
  );
}
